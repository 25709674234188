import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

interface Props {
  active: boolean;
}

export default function GnbMyStudy(props: Props) {
  const theme = useTheme();

  const colorVariants = {
    active: {
      fill: '#005F6C',
      stroke: '#005F6C',
      transition: {
        duration: 0.3,
      },
    },
    inactive: {
      fill: theme.colors.grey[400],
      stroke: theme.colors.grey[400],
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      animate={props.active ? 'active' : 'inactive'}
    >
      <motion.path
        d="M26 32.0001H14C14 26.0001 18 25.5001 20 26.0001C25.2 26.8001 26.1667 30.3334 26 32.0001Z"
        fill="#87E4DA"
        animate={props.active ? 'active' : 'inactive'}
        variants={{
          active: {
            opacity: 1,
            transition: {
              duration: 0.1,
            },
          },
          inactive: {
            opacity: 0,
            transition: {
              duration: 0.1,
            },
          },
        }}
      />
      <motion.path
        d="M33.987 32H25C25 27.1192 27.9957 26.7125 29.4935 27.1192C33.3879 27.77 34.1119 30.6442 33.987 32Z"
        fill="#87E4DA"
        animate={props.active ? 'active' : 'inactive'}
        variants={{
          active: {
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          },
          inactive: {
            opacity: 0,
            transition: {
              duration: 0.3,
            },
          },
        }}
      />
      <motion.path
        d="M25.26 33.5999H14.3403C13.9855 33.6008 13.635 33.5187 13.3156 33.3597C12.9961 33.2007 12.716 32.969 12.4964 32.6822C12.2817 32.406 12.1309 32.0831 12.0554 31.738C11.9799 31.393 11.9816 31.0348 12.0604 30.6905C12.4746 28.8991 13.4639 27.3038 14.8683 26.1623C16.2728 25.0208 18.0103 24.3999 19.8001 24.3999C21.59 24.3999 23.3275 25.0208 24.732 26.1623C26.1364 27.3038 27.1257 28.8991 27.5399 30.6905C27.6184 31.0355 27.62 31.3943 27.5445 31.7399C27.469 32.0856 27.3184 32.4092 27.1039 32.6863C26.8835 32.9719 26.6031 33.2025 26.2838 33.3607C25.9644 33.5189 25.6144 33.6007 25.26 33.5999ZM19.8001 26.452C18.4571 26.4434 17.1512 26.9052 16.0971 27.7616C15.0431 28.6179 14.3036 29.8177 14.0003 31.1638C13.9911 31.206 13.9913 31.2498 14.001 31.2918C14.0107 31.3339 14.0296 31.3731 14.0563 31.4066C14.0896 31.4489 14.1317 31.483 14.1794 31.5065C14.2272 31.53 14.2794 31.5422 14.3323 31.5424H25.252C25.3049 31.5422 25.3571 31.53 25.4049 31.5065C25.4526 31.483 25.4947 31.4489 25.528 31.4066C25.5546 31.3731 25.5736 31.3339 25.5833 31.2918C25.593 31.2498 25.5932 31.206 25.584 31.1638C25.2813 29.8205 24.5442 28.6227 23.4934 27.7667C22.4426 26.9107 21.1405 26.4472 19.8001 26.452Z"
        // fill="#005F6C"
        animate={props.active ? 'active' : 'inactive'}
        variants={colorVariants}
      />
      <motion.circle
        cx="20"
        cy="19"
        r="3"
        fill="#87E4DA"
        animate={props.active ? 'active' : 'inactive'}
        variants={{
          active: {
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          },
          inactive: {
            opacity: 0,
            transition: {
              duration: 0.3,
            },
          },
        }}
      />
      <motion.path
        d="M19.7999 23.0641C18.9429 23.0649 18.105 22.8115 17.3921 22.336C16.6791 21.8605 16.1233 21.1842 15.7948 20.3927C15.4663 19.6012 15.3799 18.7301 15.5466 17.8895C15.7133 17.0489 16.1256 16.2766 16.7313 15.6704C17.3369 15.0641 18.1088 14.6512 18.9493 14.4837C19.7897 14.3162 20.6609 14.4018 21.4527 14.7295C22.2445 15.0573 22.9213 15.6125 23.3975 16.325C23.8737 17.0375 24.1279 17.8752 24.1279 18.7321C24.1268 19.88 23.6706 20.9807 22.8593 21.7928C22.048 22.6048 20.9478 23.062 19.7999 23.0641ZM19.7999 16.4041C19.3383 16.4034 18.8869 16.5396 18.5028 16.7955C18.1187 17.0514 17.8191 17.4156 17.6422 17.8419C17.4652 18.2682 17.4186 18.7374 17.5085 19.1901C17.5983 19.6428 17.8205 20.0587 18.1469 20.3851C18.4733 20.7115 18.8892 20.9337 19.3419 21.0235C19.7946 21.1134 20.2639 21.0669 20.6901 20.8899C21.1164 20.7129 21.4806 20.4133 21.7365 20.0292C21.9925 19.6451 22.1287 19.1937 22.1279 18.7321C22.1268 18.115 21.8812 17.5235 21.4448 17.0872C21.0085 16.6508 20.417 16.4052 19.7999 16.4041Z"
        // fill="#005F6C"
        animate={props.active ? 'active' : 'inactive'}
        variants={colorVariants}
      />
      <motion.path
        d="M33.5281 33.6001H24.1938C23.9302 33.6001 23.6774 33.491 23.491 33.2968C23.3047 33.1026 23.2 32.8392 23.2 32.5646C23.2 32.2899 23.3047 32.0265 23.491 31.8323C23.6774 31.6381 23.9302 31.529 24.1938 31.529H33.5281C33.5447 31.5293 33.5612 31.5258 33.5763 31.5186C33.5914 31.5115 33.6048 31.5009 33.6155 31.4876C33.6188 31.4797 33.6205 31.4713 33.6205 31.4627C33.6205 31.4542 33.6188 31.4457 33.6155 31.4379C33.4286 30.6187 33.042 29.8635 32.4932 29.2454C31.9444 28.6273 31.2518 28.1672 30.4825 27.9094C29.7131 27.6516 28.893 27.605 28.1013 27.7739C27.3095 27.9429 26.573 28.3217 25.9629 28.8739C25.8653 28.9686 25.7504 29.0418 25.6251 29.0893C25.4998 29.1368 25.3666 29.1575 25.2334 29.1502C25.1002 29.1429 24.9698 29.1078 24.85 29.0469C24.7301 28.986 24.6232 28.9006 24.5357 28.7958C24.4482 28.691 24.3818 28.5689 24.3405 28.4368C24.2992 28.3046 24.2838 28.1652 24.2953 28.0268C24.3068 27.8884 24.345 27.7538 24.4074 27.631C24.4699 27.5083 24.5554 27.3999 24.6589 27.3123C25.5267 26.5245 26.5751 25.9837 27.7024 25.7424C28.8298 25.5011 29.9979 25.5675 31.0934 25.9351C32.1889 26.3026 33.1747 26.9589 33.9551 27.8403C34.7355 28.7218 35.2841 29.7983 35.5476 30.9657C35.6171 31.2784 35.6174 31.6035 35.5485 31.9164C35.4797 32.2293 35.3434 32.5217 35.15 32.7717C34.9561 33.0271 34.7098 33.234 34.4291 33.3774C34.1485 33.5207 33.8406 33.5968 33.5281 33.6001Z"
        // fill="#005F6C"
        animate={props.active ? 'active' : 'inactive'}
        variants={colorVariants}
      />
      <motion.circle
        cx="29"
        cy="21"
        r="2"
        fill="#87E4DA"
        animate={props.active ? 'active' : 'inactive'}
        variants={{
          active: {
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          },
          inactive: {
            opacity: 0,
            transition: {
              duration: 0.3,
            },
          },
        }}
      />
      <motion.path
        d="M29 24.68C28.2603 24.68 27.5372 24.4606 26.9222 24.0496C26.3071 23.6387 25.8278 23.0546 25.5447 22.3712C25.2616 21.6878 25.1876 20.9358 25.3319 20.2103C25.4762 19.4848 25.8324 18.8184 26.3554 18.2954C26.8785 17.7723 27.5449 17.4161 28.2704 17.2718C28.9959 17.1275 29.7479 17.2016 30.4312 17.4846C31.1146 17.7677 31.6988 18.2471 32.1097 18.8621C32.5207 19.4772 32.74 20.2003 32.74 20.94C32.74 21.4311 32.6433 21.9174 32.4553 22.3712C32.2674 22.8249 31.9919 23.2372 31.6446 23.5845C31.2973 23.9318 30.885 24.2073 30.4312 24.3953C29.9775 24.5832 29.4912 24.68 29 24.68ZM29 19.2C28.6559 19.2 28.3195 19.302 28.0333 19.4932C27.7472 19.6844 27.5242 19.9561 27.3925 20.2741C27.2608 20.592 27.2263 20.9419 27.2934 21.2794C27.3606 21.6169 27.5263 21.927 27.7696 22.1703C28.013 22.4137 28.323 22.5794 28.6606 22.6465C28.9981 22.7137 29.3479 22.6792 29.6659 22.5475C29.9838 22.4158 30.2556 22.1928 30.4468 21.9066C30.638 21.6205 30.74 21.2841 30.74 20.94C30.7358 20.4813 30.5506 20.0428 30.2248 19.7199C29.8989 19.3971 29.4587 19.2159 29 19.216V19.2Z"
        // fill="#005F6C"
        animate={props.active ? 'active' : 'inactive'}
        variants={colorVariants}
      />
    </motion.svg>
  );
}
